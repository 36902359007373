import { withTheme } from 'emotion-theming'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import LogoIcon from 'public/icons/logo.svg'

import style from './Loader.style'

const LoaderStyled = styled.div(props => ({ ...style(props) }))

const Loader = ({ height, error, maxOpacity }) => {
    return (
        <LoaderStyled height={height} maxOpacity={maxOpacity}>
            {!error && (
                <div className="loader_logo">
                    <LogoIcon />
                </div>
            )}
            {error && <div>{error}</div>}
        </LoaderStyled>
    )
}

Loader.propTypes = {
    height: PropTypes.string,
    error: PropTypes.string,
    maxOpacity: PropTypes.number
}

Loader.defaultProps = {
    height: '100vh',
    error: undefined,
    maxOpacity: 0.05
}

export default withTheme(Loader)
